<template>
  <div class="hello text-white">
    <img alt="xbts logo" src="../assets/logo.svg">
    <h1>Deposit {{currency.toUpperCase()}}</h1>
    <p>On account <a target="_blank" :href="'https://ex.xbts.io/account/'+recipient">{{recipient}}</a></p>
    <hr/>
    <button @click="selectProvider('payeer')" type="button" class="btn btn-info btn-lg">
      PAYEER {{currency.toUpperCase()}}
    </button>

    <section v-show="selectedProvider">
      <div class="container">
        <label style="font-size:1.2rem;">Amount <small>min. {{assets[currency].min}} {{currency.toUpperCase()}}</small></label>
        <input type="text" v-model="amount" @input="checkAmount" class="text-white" style="font-size:1.2rem; max-width: 200px; background: #063460; margin: 10px;"/>
        <p>
          <button :disabled="amount < assets[currency].min" @click="getInvoicePayeer" type="button" class="btn btn-success btn-lg">DEPOSIT</button>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "DepositView",
  data() {
    return {
      assets: {
        RUB: {
          title: 'Ruble',
          min: 10,
        },
        USD: {
          title: 'USD',
          min: 10,
        },
        EUR: {
          title: 'EUR',
          min: 10,
        }
      },
      currency: '',
      provider: ['payeer'],
      recipient: null,
      selectedProvider: null,
      amount: 10,
      invoice: null,
    }
  },
  methods: {
    async checkAmount() {
      this.amount = Math.floor(this.amount);
    },
    async openInvoice(url) {
      window.open(url, '_self');
    },

    async getInvoicePayeer() {
      this.invoice = null;
      try {
        this.invoice = (await axios.post('https://apis.xbts.io/payeer/invoice', {
          "currency": this.currency,
          "amount": Math.floor(this.amount),
          "address": this.recipient
        })).data;
        if (this.invoice.success) {
          await this.openInvoice(this.invoice.url);
        } else {
          //this.notify('bottom', 'right', 'danger', 'err: invoice not received')
        }
      } catch (e) {
        //this.notify('bottom', 'right', 'danger', 'err: invoice not received')
      }
    },

    async selectProvider(data) {
      this.selectedProvider = data;

    }
  },
  async created() {
    this.currency = this.$route.params.currency.toUpperCase();
    this.recipient = this.$route.query.account.trim();
    this.amount = this.assets[this.currency].min;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: #fff;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
